<template>
  <v-card>
    <v-card-title>Timeline do RI</v-card-title>

    <v-card-text>
      <v-timeline
        v-for="chat in data.data"
        :key="chat.id"
        dense
        class="timeline-custom-dense timeline-custom-dots"
      >
        <v-timeline-item
          small
          color="success"
          class="mb-6"
        >
          <div>
            <h3>{{ chat.name }}</h3>
          </div>
          <div>
            <span>{{ dateFormat(chat.created_at) }}</span>
          </div>
          <br />
          <div
            outlined
            v-html="chat.text"
          >
          </div>
        </v-timeline-item>
      </v-timeline>

      <div>
        <vue-editor v-model="descriptionReport"></vue-editor>

        <div class="mt-5 d-flex justify-end">
          <v-btn
            color="info"
            class="mr-2"
            @click="sendInconsistencyReport"
          >
            Responder
          </v-btn>
          <v-btn
            color="error"
            @click="closeModal"
          >
            Fechar
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    dataReport: {
      type: Object,
      required: true,
    },
    updatedTable: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      descriptionReport: '',
    }
  },

  created() {
    this.dateFormat()
  },

  methods: {
    async sendInconsistencyReport() {
      const ids = []

      this.dataReport.contracts_id.map(item => {
        ids.push(item.id)
      })

      const body = {
        for_users_id: this.dataReport.for_users_id,
        text: this.descriptionReport,
        contracts_id: ids,
        occurrence_id: this.dataReport.id,
      }

      await axiosIns.post('/api/v1/integration/occurrence_ri/store', body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Resposta enviada com sucesso!',
            showConfirmButton: false,
            timer: 3000,
          })
          this.$emit('close')
          this.updatedTable()
          this.descriptionReport = ''
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao responder RI!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormated = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      return dateFormated
    },

    closeModal() {
      this.$emit('close')
      this.descriptionReport = ''
    },
  },
}
</script>
