import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c('h2',[_vm._v("Inconsistências")])]),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.mode === 'general' ? _vm.itemsTable : _vm.itemsTableFilter,"loading":_vm.loadingTable,"loading-text":"Carregando dados...","disable-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.loadingCircleChat ? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.chartList(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiChatProcessingOutline)+" ")]):_vm._e(),(item.id === _vm.loadingCircleChat ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}}],null,true)}),_c(VDialog,{attrs:{"width":"600px","persistent":""},model:{value:(_vm.openModalChart),callback:function ($$v) {_vm.openModalChart=$$v},expression:"openModalChart"}},[_c('modal-chart',{attrs:{"data":_vm.itemsChatDetails,"data-report":_vm.dataRI,"updated-table":_vm.updatedTable},on:{"close":function($event){_vm.openModalChart = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }