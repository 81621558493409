import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("Timeline do RI")]),_c(VCardText,[_vm._l((_vm.data.data),function(chat){return _c(VTimeline,{key:chat.id,staticClass:"timeline-custom-dense timeline-custom-dots",attrs:{"dense":""}},[_c(VTimelineItem,{staticClass:"mb-6",attrs:{"small":"","color":"success"}},[_c('div',[_c('h3',[_vm._v(_vm._s(chat.name))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.dateFormat(chat.created_at)))])]),_c('br'),_c('div',{attrs:{"outlined":""},domProps:{"innerHTML":_vm._s(chat.text)}})])],1)}),_c('div',[_c('vue-editor',{model:{value:(_vm.descriptionReport),callback:function ($$v) {_vm.descriptionReport=$$v},expression:"descriptionReport"}}),_c('div',{staticClass:"mt-5 d-flex justify-end"},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":_vm.sendInconsistencyReport}},[_vm._v(" Responder ")]),_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.closeModal}},[_vm._v(" Fechar ")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }