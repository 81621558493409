<template>
  <v-card>
    <v-card-text>
      <h2>Inconsistências</h2>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="mode === 'general' ? itemsTable : itemsTableFilter"
      :loading="loadingTable"
      class="text-no-wrap"
      loading-text="Carregando dados..."
      disable-sort
    >
      <template #[`item.action`]="{ item }">
        <!-- <v-icon
          v-if="item.contracts_id[0].reference === loadingCircle ? false : true"
          medium
          class="me-2"
          @click="detailsItems(item)"
        >
          {{ icons.mdiEyeOutline }}
        </v-icon>
        <v-progress-circular
          v-if="item.contracts_id[0].reference === loadingCircle ? true : false"
          indeterminate
          :size="25"
          color="gray"
        ></v-progress-circular> -->

        <v-icon
          v-if="item.id === loadingCircleChat ? false : true"
          medium
          class="me-2"
          @click="chartList(item)"
        >
          {{ icons.mdiChatProcessingOutline }}
        </v-icon>
        <v-progress-circular
          v-if="item.id === loadingCircleChat ? true : false"
          indeterminate
          :size="25"
          color="gray"
        ></v-progress-circular>
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="openModalChart"
      width="600px"
      persistent
    >
      <modal-chart
        :data="itemsChatDetails"
        :data-report="dataRI"
        :updated-table="updatedTable"
        @close="openModalChart = false"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiChatProcessingOutline, mdiCheckDecagram, mdiEyeOutline, mdiMagnify, mdiSendCircleOutline, mdiTrendingUp,
} from '@mdi/js'
import ModalChart from './ModalChart.vue'

export default {
  components: {
    ModalChart,
  },
  data() {
    return {
      openModalChart: false,
      dataRI: [],
      icons: {
        mdiChatProcessingOutline,
        mdiTrendingUp,
        mdiEyeOutline,
        mdiMagnify,
        mdiCheckDecagram,
        mdiSendCircleOutline,
      },
      loadingCircle: '',
      loadingCircleChat: '',
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      loadingTable: false,
      mode: '',
      headers: [
        {
          text: 'EMISSOR', value: 'name', sortable: false, align: '',
        },

        // {
        //   text: 'DATA EMISSÃO', value: 'created_at', sortable: false, align: 'center',
        // },
        {
          text: 'REFERÊNCIA', value: 'contracts_id[0].reference', sortable: false, align: 'center',
        },
        {
          text: 'VENDEDOR', value: 'contracts_id[0].vendor_name', sortable: false, align: 'center',
        },
        {
          text: 'CONTRATO', value: 'contracts_id[0].contract_number', sortable: false, align: 'center',
        },
        {
          text: 'STATUS', value: 'contracts_id[0].controlling_status', sortable: false, align: 'center',
        },
        {
          text: 'AÇÕES', value: 'action', sortable: false, align: 'center',
        },
      ],
      itemsTable: [],
      itemsTableFilter: [],
      itemsChatDetails: [],
    }
  },

  created() {
    this.getItemsReportsInconsistencies()
  },

  methods: {
    async chartList(item) {
      this.loadingCircleChat = item.id
      const body = {
        occurrence_id: item.id,
      }
      await axiosIns.post('/api/v1/integration/occurrence_ri/chat', body)
        .then(res => {
          this.itemsChatDetails = res.data
          this.dataRI = item
          this.loadingCircleChat = ''
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao visualizar dados!',
            showConfirmButton: false,
            text: error,
            timer: 2000,
          })
        })
      this.openModalChart = true
    },
    getItemsReportsInconsistencies() {
      axiosIns.get('/api/v1/integration/occurrence_ri/index')
        .then(res => {
          this.mode = 'general'
          this.itemsTable = res.data.data
        })
    },

    updatedTable() {
      this.getItemsReportsInconsistencies()
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormated = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      return dateFormated
    },
  },
}
</script>
